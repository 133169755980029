import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Flex, useTheme } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  ApiProvider,
  AppAlert,
  Footer,
  useAppAlerts,
  AlertId,
  AlertStatus,
  BitsaccoDemoInfo,
  AuthProvider,
  FxProvider,
  BitsaccoPilotInfo,
  ServiceOutageWarning,
} from '../components';

import './Home.css';
import { BS_ENV } from '../configs';

export const Home = React.memo(function Home(): JSX.Element {
  const { appAlerts, hasAppAlert, registerAppAlert, removeAppAlert } =
    useAppAlerts();
  const location = useLocation();
  const theme = useTheme();

  const menuRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!location.pathname) {
      return;
    }

    switch (location.pathname) {
      case Routes.Savings:
      case Routes.Chamas:
        if (hasAppAlert(AlertId.Demo)) {
          return;
        }

        if (BS_ENV === 'development') {
          return registerAppAlert({
            id: AlertId.Demo,
            status: AlertStatus.Warning,
            description: <BitsaccoDemoInfo />,
          });
        }

        registerAppAlert({
          id: AlertId.Pilot,
          status: AlertStatus.Info,
          description: <BitsaccoPilotInfo />,
        });

        registerAppAlert({
          id: AlertId.ServiceOutage,
          status: AlertStatus.Warning,
          description: <ServiceOutageWarning />,
        });

        return;

      case Routes.Root:
      case Routes.Auth:
        if (hasAppAlert(AlertId.Demo)) {
          removeAppAlert(AlertId.Demo);
        }

        if (hasAppAlert(AlertId.Pilot)) {
          removeAppAlert(AlertId.Pilot);
        }

        return;
    }
  }, [location, hasAppAlert, registerAppAlert, removeAppAlert]);

  const auth = useCallback(() => {
    navigate('/auth', { state: { from: location.pathname } });
  }, [location, navigate]);

  return (
    <ApiProvider auth={auth}>
      <FxProvider>
        <AuthProvider>
          <Flex
            direction='column'
            align='center'
            minH='100vh'
            overflowX='hidden'
            className='Home'
          >
            <Header menuRef={menuRef} />
            <Box ref={menuRef} w='100%' bg={theme.colors.gray[50]}></Box>
            <Box
              w={'100%'}
              maxW='1440px'
              px={{ base: '12px', md: '20px', lg: '40px', xl: '100px' }}
            >
              {appAlerts.map((alert, idx) => (
                <AppAlert {...alert} key={idx} />
              ))}
            </Box>
            <Flex
              flexGrow={1}
              direction='column'
              w={'100%'}
              maxW='1440px'
              px={{ base: '12px', md: '20px', lg: '40px', xl: '100px' }}
              id='page-experience'
            >
              <Outlet />
            </Flex>
            <Footer />
          </Flex>
        </AuthProvider>
      </FxProvider>
    </ApiProvider>
  );
});

export enum Routes {
  Root = '/',
  Auth = '/auth',
  Account = '/account',
  Savings = '/personal',
  Chamas = '/chama',
  Join = '/chama/join',
  Transaction = '/chama/tx',
}
