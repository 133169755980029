import React, { useCallback, useState } from 'react';
import {
  ChamaTx,
  ChamaTxType,
  ChamaTxState,
  ChamaTxReview,
  ReviewChamaTx,
} from '@bitsacco/types';
import {
  Flex,
  Heading,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Text,
  Button,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FetchHeadshot, Headshot } from '../Headshot';
import { ChamaViewProps } from './types';
import { BS_API_URL, TOAST_TIMEOUT_MS } from '../../configs';
import { createCopyTextFn } from '../../utils';
import { QueryKeys } from '../../enums/QueryKeys';
import fetcher from '../../helpers/fetcher';
import { ActivityMenu } from './ActivityMenu';
import { ChamaWithdrawModal } from '../withdraw';

const ShortRowLimit = 4;

export const ChamaActivity = React.memo(function ChamaActivity({
  user,
  chama,
  isAdmin,
}: ChamaViewProps) {
  const queryClient = useQueryClient();
  const [showAll, setShowAll] = useState(false);
  const [withdrawTx, setWithdrawTx] = useState<ChamaTx | undefined>(undefined);
  const toast = useToast();

  const {
    data: transactions = [],
    isLoading: fetchLoading,
    error: fetchError,
  } = useQuery<ChamaTx[], Error>({
    queryKey: [QueryKeys.CHAMA_TX, chama.id],
    queryFn: async () => {
      const txs = await fetcher<ChamaTx[], Error>(
        `${BS_API_URL}/chama/tx/all/${chama.id}`
      );
      return txs.reverse();
    },
  });

  const displayedTransactions = showAll
    ? transactions
    : transactions.slice(0, ShortRowLimit);

  const reviewTxMutation = useMutation<ChamaTx, Error, ReviewChamaTx>({
    mutationFn: (payload: ReviewChamaTx) =>
      fetcher(`${BS_API_URL}/chama/tx/review`, 'POST', payload),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [QueryKeys.CHAMA_TX],
      });
      toast({
        title: 'Success',
        description: 'Successfully reviewed transaction',
        status: 'success',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
    onError: (e) => {
      console.error('error reviewing transaction:', e);
      toast({
        title: 'Error',
        description: 'Error reviewing transaction',
        status: 'error',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
  });

  const cancelTxMutation = useMutation<ChamaTx, Error, ReviewChamaTx>({
    mutationFn: (payload: ReviewChamaTx) =>
      fetcher(`${BS_API_URL}/chama/tx/review`, 'POST', payload),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [QueryKeys.CHAMA_TX],
      });
      toast({
        title: 'Success',
        description: 'Successfully cancelled transaction',
        status: 'success',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
    onError: (e) => {
      console.error('error cancelling transaction:', e);
      toast({
        title: 'Error',
        description: 'Error cancelling transaction',
        status: 'error',
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
  });

  const reviewTx = useCallback(
    (tx: ChamaTx, review: ChamaTxReview) => {
      const reviewer = chama.members[user.id];
      if (!reviewer) {
        console.error('error reviewing transaction: member not found');
        toast({
          title: 'Error',
          description: 'Error reviewing transaction',
          status: 'error',
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
        return;
      }

      reviewTxMutation.mutate({
        id: tx.id,
        reviewer: {
          id: reviewer.id,
          role: reviewer.role,
        },
        review,
      });
    },
    [chama, user, toast, reviewTxMutation]
  );

  const cancelTx = useCallback(
    (tx: ChamaTx) => {
      cancelTxMutation.mutate({
        id: tx.id,
        reviewer: {
          id: chama.members[user.id].id,
          role: chama.members[user.id].role,
        },
        review: ChamaTxReview.Reject,
      });
    },
    [cancelTxMutation, chama, user]
  );

  if (fetchLoading)
    return (
      <Flex w='100%' py='5rem' justify='center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Flex>
    );

  if (fetchError)
    return (
      <Flex w='100%' py='5rem' justify='center'>
        <Text>There was an error fetching chama Activity</Text>
      </Flex>
    );

  return (
    <>
      <Flex direction='column' gap={4}>
        <Heading size='sm' textTransform='uppercase'>
          Activity
        </Heading>

        <Box
          px={{ base: '', md: '3' }}
          gap={{ base: '6', lg: '6' }}
          overflowX='scroll'
          css={{ scrollbarWidth: 'none' }}
        >
          {displayedTransactions.length > 0 ? (
            <>
              <Table variant='striped'>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Description</Th>
                    <Th isNumeric>Amount (Sats)</Th>
                    <Th>Member</Th>
                    <Th>Type</Th>
                    <Th>Status</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedTransactions.map((tx) => (
                    <Tr key={tx.id}>
                      <Td>{format(new Date(tx.meta.timestamp), 'PPpp')}</Td>
                      <Td>{tx.meta.description}</Td>
                      <Td isNumeric>{(tx.amount / 1000).toFixed(2)}</Td>
                      <Td>
                        {tx.meta.user === user.id ? (
                          <Headshot user={user} />
                        ) : (
                          <FetchHeadshot id={tx.meta.user} />
                        )}
                      </Td>
                      <Td>
                        <Badge
                          colorScheme={getTxTypeColor(tx.type)}
                          variant='outline'
                          borderRadius={5}
                        >
                          {tx.type}
                        </Badge>
                      </Td>
                      <Td>
                        <Badge
                          colorScheme={getTxStateColor(tx.state)}
                          borderRadius={5}
                        >
                          {tx.state}
                        </Badge>
                      </Td>
                      <Td>
                        <ActivityMenu
                          tx={tx}
                          member={chama.members[user.id]}
                          isAdmin={isAdmin}
                          copyTxId={() =>
                            createCopyTextFn(toast)('transaction id', tx.id)
                          }
                          reviewTx={(rv: ChamaTxReview) => reviewTx(tx, rv)}
                          cancelTx={() => cancelTx(tx)}
                          withdraw={
                            tx.state === ChamaTxState.Approved
                              ? () => setWithdrawTx(tx)
                              : undefined
                          }
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {transactions.length > ShortRowLimit && (
                <Flex
                  alignItems='center'
                  justifyContent='end'
                  fontFamily='body'
                >
                  <Button mt={4} onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Show Less' : 'Show More'}
                  </Button>
                </Flex>
              )}
            </>
          ) : (
            <Text>no chama activity found</Text>
          )}
        </Box>
      </Flex>
      {withdrawTx && (
        <ChamaWithdrawModal
          tx={withdrawTx}
          chama={chama}
          isOpen={!!withdrawTx}
          onClose={() => {
            console.log('withdrawal done');
            setWithdrawTx(undefined);
          }}
        />
      )}
    </>
  );
});

const getTxTypeColor = (type: ChamaTxType) => {
  switch (type) {
    case ChamaTxType.Deposit:
      return 'green';
    case ChamaTxType.Withdrawal:
      return 'red';
  }
};

const getTxStateColor = (state: ChamaTxState) => {
  switch (state) {
    case ChamaTxState.Pending:
    case ChamaTxState.Approved:
      return 'teal';
    case ChamaTxState.Rejected:
    case ChamaTxState.Failed:
      return 'red';
    case ChamaTxState.Complete:
      return 'green';
  }
};
